import LoginLayout from "../../layout/login"
import Basic2Layout from "../../layout/basic2"

// 登录模块
export default {
    path: "/auth",
    // component: LoginLayout,
    component: Basic2Layout,
    redirect: "/auth/login",
    alwaysShow: true,
    name: "Auth",
    children: [
        {
            path: "/login",
            name: "login",
            meta: {
                config: {
                  hideBread: true
                },
                module: "login",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/auth/login")
        },
        {
            path: "/register",
            name: "register",
            meta: {
                config: {
                  hideBread: true
                },
                module: "login",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/auth/register")
        },
        {
            path: "/find_pass",
            name: "find_pass",
            meta: {
                config: {
                  hideBread: true
                },
                module: "login",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/auth/find")
        }
    ]
}
