<template>
  <div class="breadcrumb_wrap" v-if="!hideBread">
    <div class="breadcrumb_inner main-content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="cus-el-breadcrumb">
        <el-breadcrumb-item v-for="item in breadList" :key="item.name" v-bind="{ to: item.to ? { path: item.to } : false }">{{ item.title || item.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        hideBread: true,
        breadList: []
      }
    },
    created() {
      this.updateMetaInfo()
    },
    methods: {
      updateMetaInfo() {
        // 隐藏全部横条
        const { config } = this.$route.meta || {}
        //  * config.cn 页面中文名称，导航和面包屑使用，优先级低于title
        //  * config.title 区分于页面导航的中文名称 仅显示与面包屑
        //  * config.hideBread 隐藏面包屑，true 隐藏
        //  * config.breadList: [{title: ‘自定义页面标题’,to: ‘跳转路径’,isCurrent:'true 显示当前面包屑'}]， 需要自定义面包屑使用
        const { cn, title, hideBread = false, breadList } = config || {}
        const name = title || cn || this.$route.path
        if (hideBread) {
          // 不显示情况
          this.hideBread = true
        } else {
          let list = []
          list.push({ title: "首页", to: "/index" })
          if (breadList && breadList.length) {
            // 存在自定义路由信息情况
            breadList.forEach((item) => {
              if (item.isCurrent) {
                list.push({ title: name })
              } else {
                list.push(item)
              }
            })
          } else {
            // 不存在自定义路由，添加当前路由
            list.push({ title: name })
          }
          this.breadList = list
          this.hideBread = false
        }
      }
    },
    watch: {
      $route() {
        this.updateMetaInfo()
      }
    }
  }
</script>

<style lang="scss" scoped>
  // 面包屑头部红色
  .breadcrumb_wrap {
    width: 100%;
    background-color: #eee;
    height: 56px;
    display: flex;
    align-items: center;
  }
  .cus-el-breadcrumb {
    padding-left: 15px;
    font-size: 18px;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 1em;
      background: #b50003;
      position: absolute;
      left: 0;
      top: 50%;
    }
  }
</style>
