import { render, staticRenderFns } from "./NsHeader2.vue?vue&type=template&id=7a85d1df&scoped=true&"
import script from "./NsHeader2.vue?vue&type=script&lang=js&"
export * from "./NsHeader2.vue?vue&type=script&lang=js&"
import style0 from "./NsHeader2.vue?vue&type=style&index=0&id=7a85d1df&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a85d1df",
  null
  
)

export default component.exports