import Vue from "vue"
import router from "./router"
import store from "./store"
import VueMeta from "vue-meta"
import Element from "element-ui"
import * as filters from "@/filters/index"
import BaiduMap from "vue-baidu-map"

import { Message,Carousel } from "element-ui"

import imgPlaceholder from "./utils/img-placeholder";

let messageInstance = null
const overrideMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = Message(options)
}
;["error", "success", "info", "warning"].forEach((type) => {
  overrideMessage[type] = (options) => {
    if (typeof options === "string") {
      options = {
        message: options
      }
    }
    options.type = type
    return overrideMessage(options)
  }
})
Vue.use(Element)
Vue.prototype.$message = overrideMessage

import scroll from "vue-seamless-scroll"

import "element-ui/lib/theme-chalk/index.css"
import "./assets/styles/main.scss"
import "./assets/styles/element-variables.scss"

import VideoPlayer from "vue-video-player"
import "video.js/dist/video-js.css"

import App from "./App.vue"

import Util from "./utils/util"
import Lang from "./utils/lang.js"
import Config from "./utils/config.js"

Vue.prototype.$store = store //挂在vue
Vue.config.productionTip = false

// 常用工具函数
Vue.prototype.$util = Util
Vue.prototype.$img = Util.img
Vue.prototype.$timeStampTurnTime = Util.timeStampTurnTime
Vue.prototype.$copy = Util.copy
Vue.prototype.$baseRemote = "https://www.yantaisan.com/"
window.util = Util
window.img = Util.img
window.timeStampTurnTime = Util.timeStampTurnTime
window.copy = Util.copy

// 语言包
Vue.prototype.$langConfig = Lang //语言包对象
Vue.prototype.$lang = Lang.lang //解析语言包
window.langConfig = Lang
window.lang = Lang.lang

Vue.prototype.$config = Config

Vue.use(VideoPlayer)
Vue.use(VueMeta)
Vue.use(scroll)
Vue.use(Carousel)
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.use(BaiduMap, {
  /* 需要注册百度地图开发者来获取你的ak */
  ak: "PqHXuWmqCnrzPEh1kEtrK6nPF5DR0D5c"
})

// 图片展位图
Vue.directive('image', imgPlaceholder)

window.vue = new Vue({
  router,
  store,
  metaInfo() {
    return {
      // title: store.getters.siteInfo.site_name || "",
      meta: [
        // { name: "title", content: store.getters.siteInfo.site_name || "" },
        { name: "description", content: store.getters.siteInfo.seo_description || "" },
        { name: "keywords", content: store.getters.siteInfo.seo_keywords || "" }
      ]
    }
  },
  render: (h) => h(App)
}).$mount("#app")
