<template>
  <div class="header">
    <ns-header-top v-if="!isHideHeaderTop" />
    <ns-header-mid />

    <div class="nav">
      <nav class="main-content">
        <ul>
          <li v-for="(nav_item, nav_index) in navList" :key="nav_index" :class="nav_item.url == navSelect ? 'router-link-active' : ''" @click="navUrl(nav_item.url, nav_item.is_blank)">
            <span>{{ nav_item.nav_title }}</span>
          </li>
        </ul>
      </nav>
    </div>

    <div class="banner headerbanner2" v-if="showBanner">
      <el-carousel arrow="hover" v-loading="loadingAd" @change="handleChange" indicator-position="none" :height="bannerHeight">
        <el-carousel-item v-for="(item, idx) in adList" :key="item.adv_id">
          <el-image :src="$img(item.adv_image)" fit="fill" height="100%" @click="$router.pushToTab(item.adv_url)" @load="(e) => getImgHeight(e, idx)" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <header-router-meta-info />
    <breadcrumb />
  </div>
</template>

<script>
  import NsHeaderTop2 from "./NsHeaderTop2"
  import NsHeaderMid2 from "./NsHeaderMid2"
  import HeaderRouterMetaInfo from "./HeaderRouterMetaInfo"
  import Breadcrumb from "./Breadcrumb"
  import { tree, categoryConfig } from "@/api/goods/goodscategory"
  import { navList } from "@/api/website"
  import { mapGetters } from "vuex"

  import { adList } from "@/api/website"

  export default {
    props: {
      forceExpand: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters(["is_show"]),
      isHideHeaderTop() {
        return this.$route.meta.isHideHeaderTop
      }
    },
    data() {
      return {
        isShopHover: false,
        isIndex: false,
        thisRoute: "",
        goodsCategoryTree: [],
        categoryConfig: {},
        selectedCategory: -1,
        navList: [],
        navSelect: "",
        isHide: false,
        adList: [],
        loadingAd: false,
        showBanner: false,
        bannerHeight: "0"
      }
    },
    components: {
      NsHeaderTop: NsHeaderTop2,
      NsHeaderMid: NsHeaderMid2,
      HeaderRouterMetaInfo,
      Breadcrumb,
      mapGetters
    },
    beforeCreate() {},
    created() {
      this.$store.dispatch("cart/cart_count")
      this.getCategoryConfig()
      // this.getTree();
      this.nav()

      if (this.$route.path == "/" || this.$route.path == "/index") {
        this.isIndex = true
      }

      this.bannerRenderByMeta()
    },

    watch: {
      $route: function (curr) {
        this.initNav(curr.path)
        let judgeLength = localStorage.getItem("isAdList")
        if (this.$route.path == "/" || this.$route.path == "/index") this.isIndex = true
        else this.isIndex = false

        if (curr.path == "/list") {
          this.navSelect = ""
        }
        this.bannerRenderByMeta()
      }
    },
    methods: {
      getImgHeight(e, idx) {
        if (idx != 0) return
        const { src } = e.target || {}
        this.getImgSize(src).then(({ width, height }) => {
          // 1280 (设计稿尺寸)  /  真实图片宽度 * 真实图片高度 * 0.01;
          this.bannerHeight = ((1280 / width) * height * 0.01).toFixed(2) + "rem"
        })
      },
      // 获取图片尺寸
      getImgSize(url) {
        return new Promise((resolve, reject) => {
          let imgObj = new Image()
          imgObj.src = url
          imgObj.onload = () => {
            resolve({
              width: imgObj.width,
              height: imgObj.height
            })
          }
        })
      },
      bannerRenderByMeta() {
        const curr = this.$route
        if (curr.meta.bannerKey) {
          this.showBanner = true
          this.getAdList(curr.meta.bannerKey)
        } else {
          this.showBanner = false
        }
      },

      handleChange(curr, pre) {
        this.backgroundColor = this.adList[curr].background
      },

      // 加载banner
      getAdList(bannerKey) {
        this.loadingAd = true
        adList({
          keyword: bannerKey
        })
          .then((res) => {
            console.log(res, "getAdList")
            this.adList = res.data.adv_list

            // this.$store
            //   .dispatch("app/is_show", {
            //     is_show: this.adList.length
            //   })
            //   .then((res) => {})

            for (let i = 0; i < this.adList.length; i++) {
              if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
            }
            // this.backgroundColor = this.adList[0].background
            this.loadingAd = false
          })
          .catch((err) => {
            this.loadingAd = false
          })
      },

      // 获取配置
      getCategoryConfig() {
        categoryConfig({})
          .then((res) => {
            if (res.code == 0 && res.data) {
              this.categoryConfig = res.data
              this.getTree(res.data)
            }
          })
          .catch((err) => {
            this.$message.error(err.message)
          })
      },
      getTree(categoryConfig) {
        tree({
          level: 3,
          template: 2
        })
          .then((res) => {
            if (res.code == 0 && res.data) {
              this.goodsCategoryTree = res.data || []
              for (let i = 0; i < this.goodsCategoryTree.length; i++) {
                if (this.goodsCategoryTree[i].child_list > 3) {
                  this.isHide = true
                }
              }
            }
          })
          .catch((err) => {
            this.$message.error(err.message)
          })
      },
      nav() {
        navList({})
          .then((res) => {
            if (res.code == 0 && res.data) {
              this.navList = res.data
              for (let i in this.navList) {
                this.navList[i]["url"] = JSON.parse(this.navList[i]["nav_url"]).url
              }
              this.initNav(this.$route.path)
            }
          })
          .catch((err) => {
            this.$message.error(err.message)
          })
      },
      navUrl(url, target) {
        if (!url) return
        if (url.indexOf("http") == -1) {
          if (target) this.$router.pushToTab({ path: url })
          else this.$router.push({ path: url })
        } else {
          if (target) window.open(url)
          else window.location.href = url
        }
      },
      initNav(path) {
        for (let i in this.navList) {
          if (this.navList[i]["url"] == path) {
            // 屏蔽 index
            if (path == "/index") {
              this.navSelect = ""
              continue
            }
            this.navSelect = path
            continue
          }
        }
      },

      //鼠标移入显示商品分类
      shopHover() {
        this.isShopHover = true
      },
      //鼠标移出商品分类隐藏
      shopLeave() {
        this.isShopHover = false
      }
    }
  }
</script>

<style scoped lang="scss">
  .header {
    width: 100%;
    // height: 180px;
    // background-color: #fff;
    // background-color: rgba(148, 28, 18, 1);
    background-color: #a20000;

    // background-image: url("../../assets/imgs/shanfeng.png");
    // background-size: 600px;
    // background-position: 800px 0;
    // background-repeat: no-repeat;

    .shadow {
      box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
    }
    .border {
      border: 1px solid #f5f5f5;
    }

    .nav {
      // width: 1210px;
      background-color: rgba(0, 0, 0, 0.1);
      height: 50px;
      margin: 0 auto;
      position: relative;

      .shop-sort {
        width: 210px;
        height: 80px;
        color: #fff;
        background-color: $base-color;
        float: left;
        padding: 10px 10px 11px 10px;
        box-sizing: border-box;
        a {
          font-size: 14px;
          line-height: 20px;
          float: left;
          color: #fff;
        }
        i {
          font-size: 14px;
          float: right;
        }
      }
      .shop-list-content {
        width: 210px;
        height: 500px;
        position: absolute;
        left: 0;
        top: 41px;
        background-color: #333;
        display: none;
        padding: 0;
        box-sizing: border-box;
        font-size: $ns-font-size-base;
        z-index: 10;
        color: #ffffff;
        &::-webkit-scrollbar {
          display: none;
        }
        // overflow: hidden;
        .shop-list {
          width: 210px;
          height: 500px;
          overflow-y: auto;
          overflow-x: hidden;
          // box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
          &::-webkit-scrollbar {
            display: none;
          }
          a:hover {
            color: $base-color;
          }
          .list-item {
            padding-left: 12px;
            box-sizing: border-box;
            // min-height:50px;
            // height: 45px;
            // line-height: 50px;
            padding: 13px;
            a {
              display: flex;
              justify-content: space-between;
              align-items: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // width: 95%;
              // display: block;
              color: #ffffff;
              > div {
                display: flex;
                align-items: center;
              }
            }
            &:hover {
              background-color: #000;
              -webkit-transition: 0.2s ease-in-out;
              -moz-transition: -webkit-transform 0.2s ease-in-out;
              -moz-transition: 0.2s ease-in-out;
              transition: 0.2s ease-in-out;
              a:hover {
                color: #fd274a;
              }
            }
            span:hover {
              color: #fd274a;
            }
            .category-img {
              width: 17px;
              height: 17px;
              margin-right: 10px;
            }
            .category-name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .item-itm {
              font-size: 14px;
              line-height: 15px;
              height: 28px;
              overflow: hidden;

              a {
                margin-top: 5px;
                margin-right: 10px;
                color: #bfbfbf;
                &:hover {
                  color: #fd274a !important;
                }
              }

              &.item-itm-img {
                margin-left: 27px;
              }
            }
            .cate-part {
              display: none;
              position: absolute;
              left: 210px;
              top: 0;
              z-index: auto;
              // width: 998px;
              width: 760px;
              height: 498px;
              overflow-y: auto;
              border: 1px solid #f7f7f7;
              background-color: #fff;
              -webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
              -moz-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
              box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
              -webkit-transition: top 0.25s ease;
              -o-transition: top 0.25s ease;
              -moz-transition: top 0.25s ease;
              transition: top 0.25s ease;
              &.show {
                display: block;
              }
              &::-webkit-scrollbar {
                display: none;
              }
            }
            .cate-part-col1 {
              float: left;
              width: 100%;
              .cate-detail-item {
                position: relative;
                min-height: 36px;
                padding-left: 20px;
                &:last-child {
                  margin-bottom: 30px;
                }
                .cate-detail-tit {
                  margin-top: 30px;
                  margin-right: 20px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-weight: 700;
                  a {
                    display: block;
                    color: #333333;
                  }
                  a:hover {
                    color: #fd274a;
                  }
                }
                .cate-detail-con {
                  overflow: hidden;
                  padding: 6px 0 6px 17px;
                  display: flex;
                  flex-wrap: wrap;
                  // border-top: 1px dashed #eee;
                  a {
                    justify-content: start;
                    font-size: 12px;
                    height: 30px;
                    /* float: left; */
                    margin: 4px 40px 4px 0;
                    padding: 0 10px;
                    white-space: nowrap;
                    line-height: 30px;
                    color: #666;
                    width: calc((100% - 120px) / 4);
                    display: flex;
                    box-sizing: border-box;
                    margin-top: 20px;
                    .cate-detail-img {
                      width: 30px;
                      height: 30px;
                      margin-right: 10px;
                    }
                    &:nth-child(4n + 4) {
                      margin-right: 0;
                    }
                  }
                  a:hover {
                    color: #fd274a;
                  }
                }
                &:first-child .cate-detail-con {
                  border-top: none;
                }
              }
            }

            // .sub-class-right {
            // 	display: block;
            // 	width: 240px;
            // 	height: 439px;
            // 	float: right;
            // 	border-left: solid 1px #e6e6e6;
            // 	overflow: hidden;
            // 	.adv-promotions {
            // 		display: block;
            // 		height: 441px;
            // 		margin: -1px 0;
            // 		a {
            // 			background: #fff;
            // 			display: block;
            // 			width: 240px;
            // 			height: 146px;
            // 			border-top: solid 1px #e6e6e6;
            // 			img {
            // 				background: #d3d3d3;
            // 				width: 240px;
            // 				height: 146px;
            // 			}
            // 		}
            // 	}
            // }
          }
        }
      }

      .shop-list-active {
        display: block;
      }
      nav {
        height: 100%;
        width: 100%;
        font-size: 12px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.16);
        display: flex;
        // 如需左对齐，justify-content 注释掉就行
        justify-content: center;
        align-items: center;
        ul {
          margin: 0;
          padding: 0;
          width: auto;
          height: 100%;
          // padding-left: 65px;

          li {
            cursor: pointer;
            list-style: none;
            float: left;
            // color: #fffa00;
            height: 100%;
            box-sizing: border-box;
            padding: 4px 30px;
            font-size: 18px;
            color: #eed300;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
              &:hover {
                // color: $base-color;
              }
            }
          }
          li:hover {
            // color: $base-color;
            // font-weight: bold;
          }
          .router-link-active,
          li:hover {
            background-color: rgba(0, 0, 0, 0.2);
            // color: $base-color;
            // font-weight: bold;
            color: #fffb00;
            // border-bottom: 2px solid #fffa00;
          }
        }
      }
    }
  }

  .banner {
    background-color: white;
    width: 100%;
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
</style>
