<template>
  <el-container class="contaniner">
    <!-- 头部 -->
    <div class="banner basic2banner" v-loading="loadingAd" v-if="is_show && adList.length && adv_position">
      <el-carousel height="70px" arrow="hover" direction="vertical" indicator-position="none">
        <el-carousel-item v-for="item in adList" :key="item.adv_id" :style="{ backgroundColor: item.background }">
          <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
        </el-carousel-item>
      </el-carousel>
      <i class="el-icon-circle-close" @click="closeAd"></i>
    </div>
    <el-header>
      <ns-header />
    </el-header>
    <el-main>
      <transition name="slide"><router-view /></transition>
      <!-- 右侧栏 -->
      <ns-aside />
    </el-main>
    <!-- 底部 -->
    <el-footer :class="{ 'hide-footer-padding': hideTopPadding }"><ns-footer /></el-footer>
  </el-container>
</template>
<script>
  import NsHeader2 from "./components/NsHeader2"
  import NsFooter2 from "./components/NsFooter2"
  import NsAside from "./components/NsAside"
  import { adList } from "../api/website"

  export default {
    name: "Layout",
    components: {
      // NsHeaderTop,
      NsHeader: NsHeader2,
      NsFooter: NsFooter2,
      NsAside
    },
    created() {
      this.getAdList()
      this.checkHideFooterTop()
    },
    data: () => {
      return {
        loadingAd: true,
        adList: [],
        is_show: true,
        indexTopAdNum: 0,
        adv_position: {},
        hideTopPadding: false // 是否隐藏顶部 padding
      }
    },
    mounted() {},
    computed: {},
    watch: {
      $route(to, from) {
        this.checkHideFooterTop()
      }
    },
    methods: {
      getAdList() {
        if (this.$store.state.app.indexTopAdNum >= 3) {
          this.loadingAd = false
          this.is_show = false
          return
        }
        adList({ keyword: "NS_PC_INDEX_TOP" })
          .then((res) => {
            this.adList = res.data.adv_list
            this.adv_position = res.data.adv_position
            for (let i = 0; i < this.adList.length; i++) {
              if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
            }
            this.loadingAd = false
          })
          .catch((err) => {
            this.loadingAd = false
          })
      },
      closeAd() {
        this.is_show = false
        this.indexTopAdNum = this.$store.state.app.indexTopAdNum
        this.indexTopAdNum++
        this.$store.commit("app/SET_INDEXTOPADNUM", this.indexTopAdNum)
      },
      checkHideFooterTop() {
        const routeName = this.$route.name || ""
        this.hideTopPadding = ["index"].includes(routeName)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .banner {
    text-align: center;
    height: 70px;
    position: relative;
    i {
      font-size: 30px;
      position: absolute;
      z-index: 100;
      right: 280px;
      top: 10px;
      cursor: pointer;
      color: #d4d4d4;
    }
    .el-carousel {
      width: 100%;
    }
  }
  .contaniner {
    min-width: 1280px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .el-header {
    padding: 0;
    height: auto !important;
  }
  .el-footer {
    padding: 0;
    height: auto !important;
    padding-top: 0.45rem;
    background-color: #a20000;
    align-self: end;
  }
  .hide-footer-padding {
    padding-top: 0;
  }
  .el-main {
    background-color: transparent;
    border-top: none;
    display: flex;
    flex-direction: column;
  }
</style>
