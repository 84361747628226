import Basic2Layout from "../../layout/basic2"

const promotionRoutes = [
  //******************组合套餐模块（2）******************
  {
    path: "/promotion/combo-*",
    name: "combo",
    meta: {
      module: "combo"
    },
    component: () => import("@/views/promotion/combo/detail")
  },
  {
    path: "/promotion/combo_payment",
    name: "combo_payment",
    meta: {
      module: "combo",
      auth: true
    },
    component: () => import("@/views/promotion/combo/payment")
  },

  //******************秒杀模块（3）******************
  {
    path: "/promotion/seckill-*",
    name: "seckill_detail",
    meta: {
      module: "seckill",
      backgroundColor: "#fff"
    },
    component: () => import("@/views/promotion/seckill/detail")
  },
  {
    path: "/promotion/seckill",
    name: "seckill",
    meta: {
      module: "seckill",
      mainCss: {
        width: "100%"
      }
    },
    component: () => import("@/views/promotion/seckill/list")
  },
  {
    path: "/promotion/seckill_payment",
    name: "seckill_payment",
    meta: {
      module: "seckill",
      auth: true
    },
    component: () => import("@/views/promotion/seckill/payment")
  },

  //******************团购模块（3）******************
  {
    path: "/promotion/groupbuy-*",
    name: "groupbuy_detail",
    meta: {
      module: "groupbuy",
      backgroundColor: "#fff"
    },
    component: () => import("@/views/promotion/groupbuy/detail")
  },
  {
    path: "/promotion/groupbuy",
    name: "groupbuy",
    meta: {
      module: "groupbuy",
      mainCss: {
        width: "100%"
      }
    },
    component: () => import("@/views/promotion/groupbuy/list")
  },
  {
    path: "/promotion/groupbuy_payment",
    name: "groupbuy_payment",
    meta: {
      module: "groupbuy",
      auth: true
    },
    component: () => import("@/views/promotion/groupbuy/payment")
  },

  //******************专题活动模块（4）******************
  {
    path: "/promotion/topic-*",
    name: "topic_detail",
    meta: {
      module: "topic"
    },
    component: () => import("@/views/promotion/topic/detail")
  },
  {
    path: "/promotion/topic",
    name: "topic",
    meta: {
      module: "topic"
    },
    component: () => import("@/views/promotion/topic/list")
  },
  {
    path: "/promotion/topic-goods-*",
    name: "topic_goods_detail",
    meta: {
      config: {
        hideBread: true
      },
      module: "topic",
      backgroundColor: "#fff"
    },
    component: () => import("@/views/promotion/topic/goods_detail")
  },
  {
    path: "/promotion/topic_payment",
    name: "topic_payment",
    meta: {
      config: {
        hideBread: true
      },
      module: "topic",
      auth: true
    },
    component: () => import("@/views/promotion/topic/payment")
  }
]

const cmsRoutes = [
  {
    path: "/cms/notice",
    name: "notice",
    meta: {
      config: {
        hideBread: true
      },
      module: "notice",
      backgroundColor: "#fff"
    },
    component: () => import("@/views/cms/notice/list")
  },
  {
    path: "/cms/notice-*",
    name: "notice_detail",
    meta: {
      config: {
        hideBread: true
      },
      module: "notice",
      backgroundColor: "#fff"
    },
    component: () => import("@/views/cms/notice/detail")
  },
  {
    path: "/cms/help",
    name: "help",
    meta: {
      module: "help",
      backgroundColor: "#fff"
    },
    component: () => import("@/views/cms/help/list"),
    children: [
      {
        path: "/cms/help/listother-*",
        name: "list_other",
        meta: {
          config: {
            hideBread: true
          },
          module: "help",
          backgroundColor: "#fff"
        },
        component: () => import("@/views/cms/help/listother")
      }
    ]
  },
  {
    path: "/cms/help-*",
    name: "help_detail",
    meta: {
      config: {
        hideBread: true
      },
      module: "help",
      backgroundColor: "#fff"
    },
    component: () => import("@/views/cms/help/detail")
  }
]

const goodsRoutes = [
  //******************商品模块（6）******************
  // {
  // 	path: "/brand",
  // 	name: "brand",
  // 	meta: {
  // 		module: "goods",
  // 		mainCss: {
  // 			width: "100%"
  // 		}
  // 	},
  // 	component: () => import("@/views/goods/brand")
  // },
  {
    path: "/cart",
    name: "cart",
    meta: {
      config: {
        hideBread: true
      },
      module: "goods"
    },
    component: () => import("@/views/goods/cart")
  },
  {
    path: "/category",
    name: "category",
    meta: {
      module: "goods"
    },
    component: () => import("@/views/goods/category")
  },
  {
    path: "/coupon",
    name: "coupon",
    meta: {
      module: "goods"
    },
    component: () => import("@/views/goods/coupon")
  },
  {
    path: "/sku-*",
    name: "detail",
    meta: {
      config: {
        hideBread: true
      },
      module: "goods",
      backgroundColor: "#fff"
    },
    component: () => import("@/views/goods/detail")
  },
  {
    path: "/list",
    name: "list",
    meta: {
      module: "goods",
      backgroundColor: "#fff"
    },
    component: () => import("@/views/goods/list")
  }
]

/**
 * isHideHeaderTop: boolean 控制页面最上方 个人登录后的购物车等操作
 * config 参数说明
 * config.en 页面英文名称
 * config.cn 页面中文名称，导航和面包屑使用，优先级低于title
 * config.title 区分于页面导航的中文名称 仅显示与面包屑
 * config.isHidden 是否显示页面 meta 灰色横条内容，true隐藏
 * config.hideBread 隐藏面包屑，true 隐藏
 * config.breadList: [{title: ‘自定义页面标题’,to: ‘跳转路径’,isCurrent:'true 显示当前面包屑'}]， 需要自定义面包屑使用
 */
// 其他模块
export default {
  path: "/",
  component: Basic2Layout,
  redirect: "/index",
  name: "Index",
  children: [
    {
      path: "/index*",
      name: "index",
      meta: {
        config: {
          isHidden: true,
          hideBread: true
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: true,
        bannerKey: ""
      },
      component: () => import("@/views/index/index2")
    },

    {
      path: "/about",
      name: "about",
      meta: {
        config: {
          en: "about the winery",
          cn: "关于酒庄"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_ABOUT"
      },
      component: () => import("@/views/new/about")
    },
    {
      path: "/product",
      name: "product",
      meta: {
        config: {
          isHidden: true,
          cn: "产品列表"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_PRODUCT"
      },
      component: () => import("@/views/new/product")
    },

    {
      path: "/newslist",
      name: "newslist",
      meta: {
        config: {
          cn: "新闻中心",
          en: "news information"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_NEWSLIST"
      },
      component: () => import("@/views/new/newslist")
    },

    {
      path: "/newsdetail",
      name: "newsdetail",
      meta: {
        config: {
          cn: "新闻详情",
          en: "news detail",
          breadList: [{ title: "新闻中心", to: "/newslist" }, { isCurrent: true }]
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: ""
      },
      component: () => import("@/views/new/newsdetail")
    },

    {
      path: "/shortv",
      name: "shortv",
      meta: {
        config: {
          cn: "酒庄视讯",
          en: "winery video"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_SHORTV"
      },
      component: () => import("@/views/new/shorttv")
    },

    {
      path: "/dongcang",
      name: "dongcang",
      meta: {
        config: {
          cn: "洞藏实况",
          en: "dongcang facts"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_DONGCANG"
      },
      component: () => import("@/views/new/dongcang")
    },

    {
      path: "/contact",
      name: "contact",
      meta: {
        config: {
          cn: "联系我们",
          en: "contact us"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_CONTACT"
      },
      component: () => import("@/views/new/contact")
    },

    {
      path: "/join",
      name: "join",
      meta: {
        config: {
          cn: "申请加盟",
          en: "franchise/outlet"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_JOIN"
      },
      component: () => import("@/views/new/join")
    },
    {
      path: "/fengtan",
      name: "fengtan",
      meta: {
        config: {
          cn: "封坛/定制系列",
          en: "sealing altar/customized series"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_FENGTAN"
      },
      component: () => import("@/views/new/fengtan")
    },
    {
      path: "/net",
      name: "netList",
      meta: {
        config: {
          cn: "申请加盟",
          en: "franchise/outlet"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_JOIN"
      },
      component: () => import("@/views/new/netList")
    },
    {
      path: "/netdetail",
      name: "netDetail",
      meta: {
        config: {
          cn: "申请加盟",
          en: "franchise/outlet"
        },
        mainCss: {
          width: "100%"
        },
        isHideHeaderTop: false,
        bannerKey: "NS_PC_JOIN"
      },
      component: () => import("@/views/new/netDetail")
    },
    ...goodsRoutes,
    ...cmsRoutes,
    ...promotionRoutes
  ]
}
