import dayjs from "dayjs"

export const dateFormat = (date, format = "YYYY-MM-DD HH:mm") => {
  return dayjs(date).format(format)
}

export const formData2 = (date, format = "YYYY年MM月DD日  HH:mm:ss") => {
  return dayjs(date).format(format)
}

export const textMaxFilter = (text = "", len) => {
  return text.slice(0, len)
}

export const sexFilter = (number) => {
  return ["女", "男"][number]
}

export const fix2 = (number = 0) => {
  return Number.parseFloat(number).toFixed(2)
}

export const signinType = (number) => {
  return ["签到", "签退"][number]
}

export const numFix = (val, fix = 2) => {
  return parseFloat(val).toFixed(fix)
}

// 处理*展示的问题
// len 中间要隐藏的*个数
export const hideCode = (val = "", len) => {
  if (!val) return ""
  const valLen = val.length
  const left = Math.floor((valLen - len) / 2)
  const right = valLen - len - left

  const regStr = `^(.{${left}})(.{${len}})(.{${right}})$`

  const regexp = new RegExp(regStr)

  return val.replace(regexp, "$1" + "*".repeat(len) + "$3")
}
