<template>
  <div class="header-in-warp main-content">
    <div class="logo-warp">
      <img class="logo" :src="$baseRemote + '/upload/1/common/images/20240605/20240605032922171757256209953.png'" alt="" />
    </div>
    <img :src="$baseRemote + '/upload/1/common/images/20240605/20240605032409171757224925413.png'" alt="" class="kh">
    <!-- 背景图为透明，此处有没有均可 -->
    <!-- <img class="bg2" src="~@/assets/imgs/shanfeng.png" alt="" /> -->
  </div>
</template>

<script>
  export default {
    props: {},
    data() {
      return {}
    },
    components: {}
  }
</script>

<style scoped lang="scss">
  .header-in-warp {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 0.28rem 0;
    .kh{
        position: absolute;
        right:1rem;
        bottom:.15rem;
        height:auto;
      }
    .logo-warp {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      .logo {
        // 定宽 h0.5rem
        // width: 2.53rem;
        height: 0.4rem;
      }
    }

    .bg2 {
      width: 50%;
      margin-left: 12%;
    }
  }
</style>
