<template>
  <div v-if="!isHidden && cnName" class="meta-inner">
    <div class="meta-info">
      <div class="split-inner"></div>
      <span class="meta-name" :class="cnName.length > 5 ? 'fixed-text' : 'justify-text'">{{ cnName }}</span>
      <div class="split-inner">{{ enName }}</div>
    </div>
    <div class="meta-tip"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        enName: "",
        cnName: "",
        isHidden: true
      }
    },
    created() {
      this.updateMetaInfo()
    },
    methods: {
      updateMetaInfo() {
        // 隐藏全部横条
        // const { config } = this.$route.meta || {}
        // if (config && !config.isHidden) {
        //   this.enName = config.en
        //   this.cnName = config.cn
        //   this.isHidden = false
        // } else {
        //   this.isHidden = true
        // }
      }
    },
    watch: {
      $route() {
        this.updateMetaInfo()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .meta-inner {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    .meta-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      .split-inner {
        flex: 1;
        height: 18px;
        background-color: #e7e7e7;
        border-bottom: 1px solid #e95c5a;
        // 文本样式
        text-transform: uppercase;
        color: #e95c5a;
        font-family: "微软雅黑", "Source Han Serif SC", "Microsoft YaHei";
        font-size: 18px;
        line-height: 18px;
        text-indent: 6px;
        letter-spacing: 0;
        font-weight: 500;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      .meta-name {
        color: #e50c09;
        font-size: 36px;
        font-weight: bold;
        font-family: "微软雅黑", "Source Han Serif SC", "Microsoft YaHei";
        line-height: 1;
        min-width: 180px;
        &.justify-text {
          text-align: justify;
          text-align-last: justify;
          margin: 0 12px;
        }
        &.fixed-text {
          letter-spacing: 0.1em;
          margin-left: 12px;
        }
      }
    }
    .meta-tip {
      margin-top: 4px;
      align-self: center;
      height: 20px;
      width: 370px;
      background-image: url("~@/assets/imgs/meta_tip_img.png");
      background-size: 100% 100%;
    }
  }
</style>
